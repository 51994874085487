import {ApMenuUrls} from '../app/ap-interface';

export const environment = {
  Production: false,
  BackendAddress: 'https://alpha.agriport.com',
  StartRoute: ApMenuUrls.FIELD_MANAGEMENT_OVERVIEW,
  UploadSaveUrl: 'uploadSaveUrl',
  UploadRemoveUrl: 'uploadRemoveUrl',
  ButtonZoomToMapImage: '../assets/img/zoomtomap_small.png',
  AcademyUrl: 'https://academy.scrollhelp.site/academy/',
  TeamViewerUrl: 'https://get.teamviewer.com/68f6t3e',
  ParallelFileUpload: 5,
  RoundNumericPlaces: 2,
  Debug: false,
  LongRunningFunctionTimeout: 0,
  GridPageSize: 100,
  GridPageSizeOptions: [100, 250, 500],
  MaxNotifications: 1,
  WriteMissingTranslations: true,
  SpecialDecimalPlaces: {
    SoilGroups: 0,
    Distributions: 1,
    CropExtraction: 2,
    InorgFertContent: 1,
    InorgFertCaO: 1,
    InorgFertDensity: 2,
    OrgFertContent: 2,
    OrgFertDryMatter: 2,
    OrgFertDensity: 2,
    OrgFertNEff: 0
  },
  StoreKeys: {
    LastUsedLanguageKey: 'last_used_language_key',
    LastVisitFarmId: 'last_visit_farm_id',
    LastVisitUrl: 'last_visit_url',
    LastVisitUrlParams: 'last_visit_url_params',
    LastVisitCampaignYear: 'last_visit_campaignyear',
  },
  MapFactory: {
    Address: 'https://alphamaps.agriport.com',
    StoreKey: 'map-factory-session',
  }
};
